import axios from 'axios'
import { endOfDay, format, startOfDay } from 'date-fns'
import endpoints from './endpoints'

export const postCustomerOtp = async ({ email, topic, expiration = 10 }) => {
  try {
    return await axios.post(endpoints.otp.customer, {
      email,
      topic,
      expiration,
    })
  } catch (error) {
    return error.response
  }
}

export const postVerifyOtp = async ({ email, otp, topic }) => {
  try {
    return await axios.post(endpoints.otp.verify, {
      email,
      otp,
      topic,
    })
  } catch (error) {
    return error.response
  }
}
