import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getOrderRequests, getPaymentPartnerMethodQuotas, orderRequestReviewed } from '../../api'
import { FilterMatchMode } from 'primereact/api'
import { Link } from 'react-router-dom'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { useGlobalContext } from '../../context'
import ExportButton from '../../components/mini/ExportButton'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import { handleObjChange, onChangeFilter } from '../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import RequiredMessage from '../../components/mini/RequiredMessage'
import { InputNumber } from 'primereact/inputnumber'
import classNames from 'classnames'
import { InputTextarea } from 'primereact/inputtextarea'
import moneyFormatter from '../../functions/moneyFormatter'
import AvailablePaymentMethodsTable from '../../components/mini/AvailablePaymentMethodsTable'
import { Toast } from 'primereact/toast'

const emptyPaymentProcessedDetail = {
  masterid: null,
  userId: null,
  userName: null,
  paymentPartnerMethodId: null,
  gatewayName: null,
  additionalNote: null,
  clientName: null,
  paidAmount: null,
  gatewayAmountReceived: 0,
  gatewayCustomerName: null,
  paymentPartner: null,
}

export default function OrderApprovalRequestsPage() {
  const {
    orderStatus,
    user,
    paymentMethods,
    agents,
    paymentPartnerMethods,
    fetchPaymentPartnerMethods,
  } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const [data, setData] = useState([])
  const [dateCode, setDateCode] = useState(dateFilters[4].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[4].code))
  const [request, setRequest] = useState({})
  const [dataLoading, setDataLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [changeStatus, setChangeStatus] = useState(false)

  const [paymentProcessedDetail, setPaymentProcessedDetail] = useState(emptyPaymentProcessedDetail)

  const fetchRequests = useCallback(async () => {
    setDataLoading(true)
    const res = await getOrderRequests({
      ...dateFilter,
    })
    if (res) {
      setDataLoading(false)
      if (res.status === 200) {
        setData(res.data)
      }
    }
  }, [dateFilter])
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderid: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    current_status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    requested_status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    reviewedStatus: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    reviewedBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  })
  const handelApproval = async (paymentProcessedDetailRd, status) => {
    console.log(paymentProcessedDetailRd)
    const {
      id,
      paymentPartnerMethodId,
      gatewayName,
      paymentPartner,
      gatewayAmountPaid,
      additionalNote,
      createdBy,
      gatewayAmountReceived,
      gatewayProcessingFee,
      gatewayCustomerName,
    } = paymentProcessedDetailRd

    let note = `Agent Name: ${createdBy}, Gateway: ${
      gatewayName + ' - ' + paymentPartner
    }, Name on gateway: ${gatewayCustomerName}, Amount on gateway: $${gatewayAmountPaid}, Amount Received: $${gatewayAmountReceived}, Processing Fee: $${gatewayProcessingFee}, Additional Note: ${additionalNote}`
    setRequest({ id, status })
    setApproveLoading(true)
    const res = await orderRequestReviewed(
      id,
      paymentPartnerMethodId,
      gatewayName,
      paymentPartner,
      gatewayCustomerName,
      status,
      gatewayAmountReceived,
      gatewayAmountPaid,
      additionalNote,
      gatewayProcessingFee,
      note
    )
    if (res) {
      setApproveLoading(false)
      setRequest({})

      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Approved',
          detail: 'Operation Successfull',
        })
        fetchRequests()
        hideChangeStatusDialog()
      }
    }
  }

  useEffect(() => {
    fetchRequests()
  }, [fetchRequests])

  const actionBody = (rd) => {
    return !rd.reviewedAt && !rd.reviewedBy ? (
      <div className="p-d-flex gap-1by2">
        <Button
          label={
            approveLoading && request.id === rd.id && request.status === 'Approved'
              ? 'Approving'
              : 'Approve'
          }
          icon="pi pi-check-circle"
          loading={approveLoading && request.id === rd.id && request.status === 'Approved'}
          onClick={() => {
            setChangeStatus(true)
            setPaymentProcessedDetail(rd)
            fetchPaymentPartnerMethods() //TODO: Check if this is needed
          }}
        />
        <Button
          label={
            approveLoading && request.id === rd.id && request.status === 'Discarded'
              ? 'Discarding'
              : 'Discard'
          }
          icon="pi pi-times-circle"
          className="p-button-danger"
          loading={approveLoading && request.id === rd.id && request.status === 'Discarded'}
          onClick={() => handelApproval(rd, 'Discarded')}
        />
      </div>
    ) : null
  }
  const actionCreatedAt = (rd) => {
    return <div className="">{format(new Date(rd.createdAt), 'Pp')}</div>
  }
  const actionReviewedAt = (rd) => {
    return <div className="">{rd.reviewedAt ? format(new Date(rd.reviewedAt), 'Pp') : ''}</div>
  }
  const actionReviewedBy = (rd) => {
    return <div className="">{rd.reviewedBy ? rd.reviewedBy : ''}</div>
  }

  const hideChangeStatusDialog = () => {
    setChangeStatus(false)
    setPaymentProcessedDetail(emptyPaymentProcessedDetail)
  }

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'teamlead']}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-toolbar p-flex-wrap gap-1"
          left={() => (
            <div>
              <h4 className="p-m-0">Order Approval Requests</h4>
            </div>
          )}
          right={() => (
            <div className="p-d-flex p-flex-wrap gap-1 p-jc-end">
              <Button label="Refresh" icon="pi pi-refresh" onClick={fetchRequests} />
              <ExportButton datatable_ref={dt} />
            </div>
          )}
        ></Toolbar>
        <DataTable
          ref={dt}
          exportFilename={`Order_Requests_${dtFilenameDate}`}
          value={data}
          showGridlines
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          className="datatable-responsive p-datatable-customers"
          columnResizeMode="fit"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
          emptyMessage="No request found."
          rowHover
          breakpoint="1200px"
          responsiveLayout="stack"
          filters={filters}
          loading={dataLoading}
          header={
            <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
              <div className="">
                <Dropdown
                  options={orderStatus}
                  filter
                  filterBy="name"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Current Status"
                  showClear
                  value={filters.current_status.value}
                  onChange={(e) => {
                    onChangeFilter(setFilters, e.value, 'current_status')
                  }}
                />
              </div>
              <div className="">
                <Dropdown
                  options={user?.members?.filter((m) => m.role === 'agent')}
                  filter
                  filterBy="username"
                  optionLabel="username"
                  optionValue="username"
                  placeholder="Requested By"
                  showClear
                  value={filters.createdBy.value}
                  onChange={(e) => {
                    onChangeFilter(setFilters, e.value, 'createdBy')
                  }}
                />
              </div>
              <div className="">
                <Dropdown
                  options={['Approved', 'Discarded']}
                  placeholder="Reviewed Status"
                  showClear
                  value={filters.reviewedStatus.value}
                  onChange={(e) => {
                    onChangeFilter(setFilters, e.value, 'reviewedStatus')
                  }}
                />
              </div>
              <div>
                <MyDatesPicker
                  code={dateCode}
                  setCode={setDateCode}
                  rangeDates={dateRange}
                  setRangeDates={setDateRange}
                  filterDates={dateFilter}
                  setFilterDates={setDateFilter}
                />
              </div>

              <div className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  value={filters.global.value}
                  onChange={(e) => {
                    onChangeFilter(setFilters, e.target.value, 'global')
                  }}
                  placeholder="Search"
                  sho
                />
              </div>
            </div>
          }
        >
          <Column header="Id" field="id" />
          <Column
            header="Invoice Id"
            field="orderid"
            body={(rd) => (
              <Link to={`/orders?invId=${rd.orderid}`} target="_blank">
                {rd.orderid}
              </Link>
            )}
            style={{ width: '0%' }}
          />
          <Column header="Current Status" field="current_status" style={{ width: '0%' }} />
          <Column header="Requested Status" field="requested_status" style={{ width: '0%' }} />
          <Column header="Requested By" field="createdBy" style={{ width: '0%' }} />
          <Column header="Note" field="note" />
          <Column
            header="Request Date"
            field="createdAt"
            body={actionCreatedAt}
            style={{ width: '0%' }}
          />
          <Column header="Reviewed Status" field="reviewedStatus" style={{ width: '0%' }} />
          <Column
            header="Reviewed By"
            field="reviewedBy"
            body={actionReviewedBy}
            style={{ width: '0%' }}
          />
          <Column
            header="Reviewed Date"
            field="reviewedAt"
            body={actionReviewedAt}
            style={{ width: '0%' }}
          />
          <Column style={{ width: '0%' }} header="Action" body={actionBody} />
        </DataTable>
      </div>
      <Dialog
        autoFocus
        style={{ width: '450px' }}
        header={`Approve Order Status For (${paymentProcessedDetail.orderid})`}
        modal
        className=""
        visible={changeStatus}
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              disabled={approveLoading}
              onClick={() => hideChangeStatusDialog()}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              loading={approveLoading}
              onClick={() => {
                handelApproval(paymentProcessedDetail, 'Approved')
              }}
              autoFocus
            />
          </>
        }
        onHide={() => hideChangeStatusDialog()}
      >
        <div className="confirmation-content p-d-flex p-ai-start">
          <div>
            <div className="p-mt-4">
              <h6 className="">Payment Details</h6>
              <hr />
              <div className="p-grid p-formgrid p-fluid">
                <div className="p-field p-col-12">
                  {/* <label>Details</label> */}
                  <p>{paymentProcessedDetail.note}</p>
                </div>
                <div className="p-field p-col-12">
                  <label>
                    Payment Partner Method {''}
                    <RequiredMessage value={paymentProcessedDetail.paymentPartnerMethodId} />
                  </label>
                  <Dropdown
                    options={paymentPartnerMethods ?? []}
                    optionLabel="paymentPartnerMethod"
                    optionValue="id"
                    placeholder="Select Payment Partner Method"
                    value={paymentProcessedDetail.paymentPartnerMethodId}
                    onChange={(e) => {
                      const item = paymentPartnerMethods.find((i) => i.id === e.value)
                      if (!item || !item.active) return
                      handleObjChange(setPaymentProcessedDetail, 'paymentPartnerMethodId', e.value)
                      handleObjChange(
                        setPaymentProcessedDetail,
                        'paymentPartnerMethodName',
                        item.name
                      )
                      handleObjChange(setPaymentProcessedDetail, 'gatewayName', item.appName)
                      handleObjChange(
                        setPaymentProcessedDetail,
                        'paymentPartner',
                        item.paymentPartner
                      )
                    }}
                    itemTemplate={(option) => (
                      <span
                        className={classNames({
                          'opacity-50': !option?.active,
                        })}
                      >
                        {option?.paymentPartnerMethod}
                      </span>
                    )}
                  />
                </div>
                <div className="p-field p-col-12">
                  <label>Name On Gateway</label>
                  <InputText
                    placeholder="Name On Gateway"
                    value={paymentProcessedDetail.gatewayCustomerName}
                    onChange={(e) =>
                      handleObjChange(
                        setPaymentProcessedDetail,
                        'gatewayCustomerName',
                        e.target.value
                      )
                    }
                    disabled
                  />
                </div>
                <div className="p-field p-col-12">
                  <label> Amount On Gateway</label>
                  <InputNumber
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    value={paymentProcessedDetail?.gatewayAmountPaid || 0}
                    onChange={(e) =>
                      handleObjChange(setPaymentProcessedDetail, 'gatewayAmountPaid', e.value)
                    }
                  />
                </div>
                <div className="p-field p-col-12">
                  <label>
                    Amount Received {''}
                    <RequiredMessage value={paymentProcessedDetail?.gatewayAmountReceived} />{' '}
                    (Processing Fee: {''}
                    {moneyFormatter(paymentProcessedDetail?.gatewayProcessingFee || 0, '$')})
                  </label>
                  <InputNumber
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    value={paymentProcessedDetail?.gatewayAmountReceived || 0}
                    onChange={(e) => {
                      handleObjChange(setPaymentProcessedDetail, 'gatewayAmountReceived', e.value)
                      handleObjChange(
                        setPaymentProcessedDetail,
                        'gatewayProcessingFee',
                        paymentProcessedDetail.gatewayAmountPaid - e.value
                      )
                    }}
                    placeholder="Enter Gateway Amount Received"
                  />
                </div>
                <div className="p-field p-col-12">
                  <label>Additional Note</label>
                  <InputText
                    placeholder="Additional Note"
                    value={paymentProcessedDetail.additionalNote}
                    onChange={(e) =>
                      handleObjChange(setPaymentProcessedDetail, 'additionalNote', e.target.value)
                    }
                  />
                </div>
              </div>
              <AvailablePaymentMethodsTable
                totalAmount={paymentProcessedDetail?.gatewayAmountPaid}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </PageAllowedToRoles>
  )
}
